tr .inspection-item-table-no-items {
	display: flex;
	margin-top: 0.5em;
	border-bottom: none;
}

.inspection-item-table-container {
	max-width: 100%;
	max-height: 65vh; /* Adjust the height as needed */
	overflow: auto;
	margin-bottom: 1em;
}

.inspection-item-table {
	width: 100%;
	table-layout: fixed; /* Optional: Ensures table columns are evenly distributed */
}
