.inspection-additional-info-view {
	display: grid;
	grid-template-rows: 0.5fr 1fr 1fr 0.1fr 1fr;
	width: 100%;
}
.inspection-additional-info-view-upper {
	display: grid;
	grid-template-columns: 1fr 1fr;
}
.inspection-additiona-info-calendar {
	display: flex;
	align-items: center;
	justify-content: center;
}
.inspection-additional-info-view-hours {
	display: grid;
	grid-template-rows: 1fr 1fr;
}
.inspection-additional-info-view-hours-divs {
	display: flex;
	justify-content: space-around;
}
.inspection-additional-info-billinghour-textfield {
	width: 100px;
}
.inspection-additional-info-comment {
	margin-top: 2em;
}
.inspection-additional-info-buttons {
	display: grid;
	grid-template-rows: 0.6fr 1fr;
	margin-top: 0.5em;
}
.inspection-additional-info-buttons-upper {
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr;
	justify-items: center;
	height: 90%;
}
